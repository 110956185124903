import { Options } from './qrcode'

interface Matrix {
  mat: boolean[][]
  size?: number
}

// Function to generate moves based on options
const generateMoves = (opts: Options): Record<string, string> => {
  opts.corners = opts.corners
  opts.radius = opts.radius != null ? opts.radius : 1
  const moves: Record<string, string> = {
    u: 'v-2',
    r: 'h2',
    d: 'v2',
    l: 'h-2',
  }

  ;['ld', 'ul', 'ru', 'dr', 'ur', 'rd', 'dl', 'lu'].forEach((i, j) => {
    let mode =
      typeof opts.corners === 'string'
        ? opts.corners
        : opts.corners[i] || opts.corners[[...i].reverse().join('')] || 'None'
    let radius =
      typeof opts.radius === 'number'
        ? opts.radius
        : opts.radius[i] || opts.radius[[...i].reverse().join('')] || 1
    const sides: any = [
      { d: [0, 1], l: [-1, 0], u: [0, -1], r: [1, 0] }[i[0]],
      { u: [0, -1], r: [1, 0], d: [0, 1], l: [-1, 0] }[i[1]],
    ]
    switch (mode) {
      case 'None':
        moves[i] =
          { d: 'v', l: 'h-', u: 'v-', r: 'h' }[i[0]] +
          '1' +
          { u: 'v-', r: 'h', d: 'v', l: 'h-' }[i[1]] +
          '1'
        break
      case 'Skew':
        moves[i] =
          (radius < 1
            ? { d: 'v', l: 'h-', u: 'v-', r: 'h' }[i[0]] + `${1 - radius}`
            : '') +
          'l' +
          (sides[0][0] + sides[1][0]) * radius +
          ' ' +
          (sides[0][1] + sides[1][1]) * radius +
          (radius < 1
            ? { u: 'v-', r: 'h', d: 'v', l: 'h-' }[i[1]] + `${1 - radius}`
            : '')
        break
      case 'Rounded':
        moves[i] =
          (radius < 1
            ? { d: 'v', l: 'h-', u: 'v-', r: 'h' }[i[0]] + `${1 - radius}`
            : '') +
          'a' +
          `${radius},${radius} 0 0,${j > 3 ? 1 : 0}` +
          ' ' +
          (sides[0][0] + sides[1][0]) * radius +
          ',' +
          (sides[0][1] + sides[1][1]) * radius +
          (radius < 1
            ? { u: 'v-', r: 'h', d: 'v', l: 'h-' }[i[1]] + `${1 - radius}`
            : '')
        break
      default:
        moves[i] = mode
    }
  })

  return moves
}

// Function to generate the SVG path
export const generateSvgPath = (
  { mat, size = mat.length }: Matrix,
  options: Options,
): string => {
  const moves = generateMoves(options)

  let d /*drawn*/ = Array(size * 2 + 3)
    .fill(0)
    .map(() => Array(size + 1).fill(false))
  let f /*filled*/ = [
    Array(size).fill(false),
    ...mat,
    Array(size).fill(false),
  ].map((a) => [false, ...a, false])

  let paths = []
  for (let x = 0; x < size; x++)
    for (let y = 0; y < size * 2; y += 2)
      if (!d[y][x] && !f[y / 2][1 + x] && f[y / 2 + 1][1 + x]) {
        let lpaths = [`M${x * 2 + 1} ${y}`],
          dir = 0
        while (!d[y][x]) {
          d[y][x] = true
          switch ((y % 2) * 2 + dir) {
            case 0b00: // Path going right
              x++
              if (f[y / 2 + 1][1 + x]) {
                if (f[y / 2][1 + x]) {
                  lpaths.push(moves.ru)
                  dir = 0
                  y--
                } else {
                  lpaths.push(moves.r)
                }
              } else {
                lpaths.push(moves.rd)
                dir = 1
                y++
              }
              break
            case 0b01: // Path going left
              if (f[y / 2][x]) {
                if (f[y / 2 + 1][x]) {
                  lpaths.push(moves.ld)
                  dir = 1
                  y++
                } else {
                  lpaths.push(moves.l)
                  x--
                }
              } else {
                lpaths.push(moves.lu)
                dir = 0
                y--
              }
              break
            case 0b10: // Path going up
              if (f[(y - 1) / 2][1 + x]) {
                if (f[(y - 1) / 2][1 + x - 1]) {
                  lpaths.push(moves.ul)
                  dir = 1
                  x--
                } else {
                  lpaths.push(moves.u)
                  y--
                }
              } else {
                lpaths.push(moves.ur)
                dir = 0
              }
              y--
              break
            case 0b11: // Path going down
              if (f[(y + 3) / 2][1 + x - 1]) {
                if (f[(y + 3) / 2][1 + x]) {
                  lpaths.push(moves.dr)
                  dir = 0
                } else {
                  lpaths.push(moves.d)
                  y++
                }
              } else {
                lpaths.push(moves.dl)
                dir = 1
                x--
              }
              y++
              break
          }
        }
        paths.push(lpaths.join(''))
      }
  return paths.join('')
}

// Function to generate the SVG
export const generateSvg = (
  { mat, size = mat.length }: Matrix,
  options: Options,
  svg: SvgType,
): string => {
  const viewBox = `${0 - svg.padding} ${0 - svg.padding} ${
    (svg.size + svg.padding) * 2
  } ${(svg.size + svg.padding) * 2}`

  const logoSize = size / 2
  const logoOffset = size - logoSize / 2

  const logoPos = {
    x: logoOffset.toFixed(2),
    y: logoOffset.toFixed(2),
    w: logoSize.toFixed(2),
    h: logoSize.toFixed(2),
  }

  let newLogoPath = ''

  if (options.hasLogo) {
    const newLogoPaths = svg.logoPaths
      .map((logoPath) => `<path fill="${options.logoColor}" d="${logoPath}" />`)
      .join('')

    newLogoPath = `<svg width="${String(logoPos.w)}" height="${String(
      logoPos.h,
    )}" x="${String(logoPos.x)}" y="${String(
      logoPos.y,
    )}" fill="none" viewBox="0 0 1000 1000">
      ${newLogoPaths}
    </svg>`
  }

  const eyePath = `
    <g>
      <rect x="4" y="4" width="6" height="6" rx="1" ry="1" style="fill: ${
        options.foregroundColor
      };"/>
      <path d="m11,0H3C1.34,0,0,1.34,0,3v8c0,1.66,1.34,3,3,3h8c1.66,0,3-1.34,3-3V3c0-1.66-1.34-3-3-3Zm1,10c0,1.1-.89,2-2,2H4c-1.1,0-2-.89-2-2V4c0-1.1.89-2,2-2h6.01c1.1,0,2,.89,2,2v6.01Z" style="fill: ${
        options.foregroundColor
      }; fill-rule: evenodd;"/>
    </g>
    <g>
      <rect x="${
        size * 2 - 10
      }" y="4" width="6" height="6" rx="1" ry="1" style="fill: ${
        options.foregroundColor
      };"/>
      <path  transform="translate(${
        size * 2 - 14
      },0)" d="m11,0H3C1.34,0,0,1.34,0,3v8c0,1.66,1.34,3,3,3h8c1.66,0,3-1.34,3-3V3c0-1.66-1.34-3-3-3Zm1,10c0,1.1-.89,2-2,2H4c-1.1,0-2-.89-2-2V4c0-1.1.89-2,2-2h6.01c1.1,0,2,.89,2,2v6.01Z" style="fill: ${
        options.foregroundColor
      }; fill-rule: evenodd;"/>
    </g>
    <g>
      <rect x="4" y="${
        size * 2 - 10
      }" width="6" height="6" rx="1" ry="1" style="fill: ${
        options.foregroundColor
      };"/>
      <path transform="translate(0,${
        size * 2 - 14
      })" d="m11,0H3C1.34,0,0,1.34,0,3v8c0,1.66,1.34,3,3,3h8c1.66,0,3-1.34,3-3V3c0-1.66-1.34-3-3-3Zm1,10c0,1.1-.89,2-2,2H4c-1.1,0-2-.89-2-2V4c0-1.1.89-2,2-2h6.01c1.1,0,2,.89,2,2v6.01Z" style="fill: ${
        options.foregroundColor
      }; fill-rule: evenodd;"/>
    </g>
  `

  return `<?xml version="1.0"?><svg xmlns="http://www.w3.org/2000/svg" xmlns:svg="http://www.w3.org/2000/svg" version="1.1" viewBox="${viewBox}"><g class="layer">
  <title>Layer 1</title><path d="${svg.qrcode}" fill-rule="evenodd" fill="${options.foregroundColor}" id="svg_1" /></g>${eyePath}${newLogoPath}</svg>`
}

export type SvgType = {
  logoPaths: string[]
  eye1: string
  eye2: string
  eye3: string
  qrcode: string
  padding: number
  size: number
}

export const generateSvgObject = (
  { mat, size = mat.length }: Matrix,
  options: Options,
): SvgType => {
  let logoPaths = [
    'M569.188 589.633C569.101 579.772 567.8 569.451 564.532 560.194C553.024 527.536 519.05 508.705 484.96 514.972C451.593 521.125 427.102 550.305 427.044 584.832C426.924 671.996 426.964 759.16 426.981 846.324C426.986 874.753 404.05 897.926 375.461 898.382C346.217 898.847 322.258 875.405 322.249 846.324C322.223 757.366 322.167 668.421 322.432 579.456C322.461 567.525 323.993 555.393 326.451 543.692C344.06 459.717 424.008 400.466 510.78 406.676C597.235 412.857 668.682 482.141 672.788 567.611C674.462 602.231 674.838 661.789 674.924 705.434C674.983 734.91 650.261 759.51 620.615 759.51C592.378 759.51 569.291 737.119 569.184 709.043C569.033 669.236 569.553 629.413 569.188 589.604',
    'M936.606 759.534C907.998 759.534 885.403 737.226 885.373 708.783C885.332 670.644 885.264 620.196 884.843 590.807C884.525 569.159 883.455 547.252 879.725 525.978C843.727 322.149 659.716 184.96 453.469 207.499C277.294 226.732 134.833 367.803 114.072 543.745C111.034 569.325 90.2517 589.168 64.3453 589.168H60.4418C30.6244 589.168 7.47325 565.142 10.2217 535.621C11.3619 523.375 12.7053 511.554 14.1728 503.296C65.4959 215.175 362.562 33.3678 642.656 123.007C833.404 184.069 946.633 317.607 983.846 513.875C989.116 541.678 989.992 643.197 990 706.703C990.004 735.993 966.064 759.534 936.606 759.534Z',
    'M725.845 586.149C725.829 586.149 725.816 586.137 725.816 586.121C725.7 573.797 725.15 561.387 723.532 549.207C706.299 420.643 578.816 331.866 451.39 359.465C343.915 382.752 270.212 474.029 270.01 584.626C269.841 671.691 269.954 758.755 269.996 845.82C270.01 874.365 246.888 897.584 218.181 897.868C189.067 898.155 165.31 874.769 165.292 845.82C165.237 759.762 165.115 673.708 165.571 587.673C165.658 567.405 167.566 546.936 170.863 526.898C200.124 349.461 377.658 225.898 555.569 258.672C717.287 288.484 830.573 423.748 830.631 587.098C830.631 624.398 831.021 767.393 831.348 846.566C831.467 875.641 807.153 900 777.91 900C749.75 900 726.894 877.354 726.796 849.355L725.875 586.178C725.874 586.162 725.861 586.149 725.845 586.149Z',
  ]

  return {
    logoPaths,
    eye1: 'm11,0H3C1.34,0,0,1.34,0,3v8c0,1.66,1.34,3,3,3h8c1.66,0,3-1.34,3-3V3c0-1.66-1.34-3-3-3Zm1,10c0,1.1-.89,2-2,2H4c-1.1,0-2-.89-2-2V4c0-1.1.89-2,2-2h6.01c1.1,0,2,.89,2,2v6.01Z',
    eye2: 'm11,0H3C1.34,0,0,1.34,0,3v8c0,1.66,1.34,3,3,3h8c1.66,0,3-1.34,3-3V3c0-1.66-1.34-3-3-3Zm1,10c0,1.1-.89,2-2,2H4c-1.1,0-2-.89-2-2V4c0-1.1.89-2,2-2h6.01c1.1,0,2,.89,2,2v6.01Z',
    eye3: 'm11,0H3C1.34,0,0,1.34,0,3v8c0,1.66,1.34,3,3,3h8c1.66,0,3-1.34,3-3V3c0-1.66-1.34-3-3-3Zm1,10c0,1.1-.89,2-2,2H4c-1.1,0-2-.89-2-2V4c0-1.1.89-2,2-2h6.01c1.1,0,2,.89,2,2v6.01Z',
    qrcode: generateSvgPath({ mat, size }, options),
    padding: 2 * options.padding,
    size,
  }
}
