import { CSSProperties, useEffect, useState } from 'react'

import { QrThemeType } from '@/lib/data/qr-themes'
import { finalSvgQr } from '@/lib/qrcode/final-svg-qr'

type Props = {
  theme: QrThemeType
  data: string
  className?: string
  style?: CSSProperties
  hasLogo?: boolean
  correction?: ErrorCorrectionLevel
}

export const QrHtmlInnerElement = ({
  theme,
  data,
  className,
  style,
  hasLogo,
  correction,
}: Props) => {
  const [image, setImage] = useState<string>('')

  useEffect(() => {
    async function fetchData() {
      const qrcode = finalSvgQr({
        data,
        foregroundColor: theme.foregroundColor,
        logoColor: theme.logoColor,
        hasLogo,
        correction,
      })

      setImage(qrcode)
    }

    fetchData()
  }, [correction, data, hasLogo, theme])

  return (
    <div
      dangerouslySetInnerHTML={{ __html: image }}
      className={className}
      style={style}
    />
  )
}
