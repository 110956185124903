import { defaultOptions, svgQRCode } from './qrcode'

type FinalSvgQrInput = {
  data: string
  foregroundColor: string
  logoColor: string
  padding?: number
  hasLogo?: boolean
  correction?: ErrorCorrectionLevel
}

export const finalSvgQr = ({
  data,
  correction = 'H',
  foregroundColor,
  logoColor,
  padding,
  hasLogo = true,
}: FinalSvgQrInput): string => {
  const qrcode = svgQRCode({
    data,
    opts: {
      ...defaultOptions,
      padding: padding ?? defaultOptions.padding,
      foregroundColor,
      logoColor,
      hasLogo,
      correction,
    },
  })

  return qrcode
}
