// Import necessary libraries
import QRCodeModel from 'qrcode-generator'

import { generateSvg, generateSvgObject, SvgType } from './svg'

type TypeNumber =
  | 0 // Automatic type number
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | 13
  | 14
  | 15
  | 16
  | 17
  | 18
  | 19
  | 20
  | 21
  | 22
  | 23
  | 24
  | 25
  | 26
  | 27
  | 28
  | 29
  | 30
  | 31
  | 32
  | 33
  | 34
  | 35
  | 36
  | 37
  | 38
  | 39
  | 40

type ErrorCorrectionLevel = 'L' | 'M' | 'Q' | 'H'

type Mode = 'Numeric' | 'Alphanumeric' | 'Byte' /* Default */ | 'Kanji'

// Define options type
export interface Options {
  typeNumber: TypeNumber // QR code version, 0 (auto) | from 1 to 40
  correction: ErrorCorrectionLevel // Error correction level, 'L' (7%) | 'M' (15%) | 'Q' (25%) | 'H' (30%)
  mode: Mode
  corners: 'None' | 'Rounded' | 'Skew' // Corners style, 'None' | 'Rounded' | 'Skew'
  radius: number // Radius for 'Rounded' | 'Skew' styling
  padding: number // Number of clear modules around QR
  foregroundColor: string
  logoColor: string
  hasLogo: boolean
}

export const defaultOptions: Options = {
  typeNumber: 0,
  correction: 'H',
  mode: 'Byte',
  corners: 'Rounded',
  radius: 1,
  padding: 0,
  foregroundColor: '#000',
  logoColor: '#000',
  hasLogo: true,
}

// Function to encode the data
const encode = (
  data: string,
  opts: Options,
): { size: number; mat: boolean[][] } => {
  let qr = QRCodeModel(opts.typeNumber, opts.correction)
  qr.addData(data, opts.mode)
  qr.make()

  const size = qr.getModuleCount()

  // Calculate the center space dimensions (1/3 width x 1/3 height)
  let centerSpaceStart = Math.round(size / 3)
  let centerSpaceEnd = Math.round((size * 2) / 3)

  let mat: boolean[][] = []
  for (let i = 0; i < size; i++) {
    mat[i] = []
    for (let j = 0; j < size; j++) {
      // Add center space condition
      if (
        opts.hasLogo &&
        i >= centerSpaceStart &&
        i < centerSpaceEnd &&
        j >= centerSpaceStart &&
        j < centerSpaceEnd
      ) {
        mat[i][j] = false
      }
      // Check for "eyes" in the corners and avoid filling them
      else if (
        // top-left corner
        (i < 7 && j < 7) ||
        // top-right corner
        (i < 7 && j >= size - 7) ||
        // bottom-left corner
        (i >= size - 7 && j < 7)
      ) {
        mat[i][j] = false
      } else {
        mat[i][j] = qr.isDark(i, j)
      }
    }
  }

  return {
    size,
    mat,
  }
}

// Function to render the SVG
export const svgQRCode = ({
  data,
  opts = defaultOptions,
}: {
  data: string
  opts?: Options
}) => {
  let qr = encode(data, opts)
  const svg = generateSvgObject(qr, opts)
  return generateSvg(qr, opts, svg)
}

export const pdfSvgQRCode = ({
  data,
  opts = defaultOptions,
}: {
  data: string
  opts?: Options
}): SvgType => {
  let qr = encode(data, opts)
  return generateSvgObject(qr, opts)
}
